import React, { useEffect, useState } from 'react'
import { motion, MotionProps } from 'framer-motion'
import { Icon, IconProps } from '@chakra-ui/react'

import { EYEBROW_THEMES } from 'components/Eyebrow'
import { MotionIcon } from 'components/Motion'

import { getUID } from 'utils/miscHelper'
import colors from 'constants/colors'

export type MotionIconType = MotionProps & IconProps

export type IconThreeLinesType = {
  themeType?: keyof typeof EYEBROW_THEMES
} & IconProps

export const Close: React.FC = (props) => (
  <Icon viewBox="0 0 12 12" {...props}>
    <rect x={1.757} y={0.343} width={14} height={2} rx={1} transform="rotate(45 1.757 .343)" fill="#fff" />
    <rect x={0.343} y={10.243} width={14} height={2} rx={1} transform="rotate(-45 .343 10.243)" fill="#fff" />
  </Icon>
)

export const IconArrow = (props: MotionIconType) => {
  const [UID, setUID] = useState(null)

  useEffect(() => {
    setUID(getUID()) // Safari render fix
  }, [])

  return (
    <MotionIcon viewBox="0 0 18 20" fill="none" {...props}>
      <mask
        id={`iconArrowc${UID}`}
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="3"
        width="18"
        height="14"
      >
        <mask
          id={`iconArrowb${UID}`}
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="18"
          height="20"
        >
          <g clipPath={`url(#iconArrowa${UID})`} stroke="#00C0F0" strokeWidth="1.555" strokeMiterlimit="10">
            <path d="m7.773 1.485 8.516 8.513-8.516 8.515M16.27 10.06H-1.627" />
          </g>
        </mask>
        <g mask={`url(#iconArrowb${UID})`}>
          <path fill="currentcolor" d="M-1.709 3.307H18.37v13.386H-1.709z" />
        </g>
      </mask>
      <g mask={`url(#iconArrowc${UID})`} fill="currentcolor">
        <path d="M4.623 2.183h15.025v4.665H4.623V2.183ZM.604 9.22h19.638v7.777H.604V9.22Z" />
      </g>
      <defs>
        <clipPath id={`iconArrowa${UID}`}>
          <path fill="currentcolor" transform="translate(0 .63)" d="M0 0h18v18.741H0z" />
        </clipPath>
      </defs>
    </MotionIcon>
  )
}

export const IconTwitter = (props: IconProps) => (
  <Icon viewBox="0 0 22 22" w="unset" h="unset" {...props}>
    <path
      d="M18.137 7.621a7.818 7.818 0 0 0 1.828-1.898 6.918 6.918 0 0 1-2.11.562 3.607 3.607 0 0 0 1.618-2.039 7.798 7.798 0 0 1-2.32.914A3.691 3.691 0 0 0 14.444 4a3.69 3.69 0 0 0-3.691 3.691c0 .282.035.563.105.844A10.71 10.71 0 0 1 3.23 4.668a3.59 3.59 0 0 0-.492 1.863c0 1.266.633 2.39 1.653 3.059-.598-.035-1.196-.176-1.688-.457v.035a3.696 3.696 0 0 0 2.953 3.621c-.281.07-.633.14-.949.14-.246 0-.457-.034-.703-.07.457 1.477 1.828 2.532 3.445 2.567a7.422 7.422 0 0 1-4.57 1.582c-.317 0-.598-.035-.879-.07 1.617 1.054 3.55 1.652 5.66 1.652 6.785 0 10.477-5.59 10.477-10.477v-.492Z"
      fill="currentcolor"
      fillRule="evenodd"
    />
  </Icon>
)

export const IconFacebook = (props: IconProps) => (
  <Icon viewBox="0 0 22 22" w="unset" h="unset" {...props}>
    <path
      d="M17.063 3H4.688C3.737 3 3 3.773 3 4.688v12.375c0 .949.738 1.687 1.688 1.687h4.816v-5.344H7.289v-2.531h2.215V8.977c0-2.18 1.3-3.41 3.27-3.41.984 0 1.968.175 1.968.175v2.145h-1.09c-1.09 0-1.441.668-1.441 1.37v1.618h2.426l-.387 2.531h-2.04v5.344h4.852c.915 0 1.688-.738 1.688-1.688V4.688c0-.914-.773-1.687-1.688-1.687Z"
      fill="currentcolor"
      fillRule="evenodd"
    />
  </Icon>
)

export const IconLinkedin = (props: IconProps) => (
  <Icon viewBox="0 0 22 22" w="unset" h="unset" {...props}>
    <path
      d="M17.625 3H4.09C3.492 3 3 3.527 3 4.16v13.465c0 .633.492 1.125 1.09 1.125h13.535c.598 0 1.125-.492 1.125-1.125V4.16c0-.633-.527-1.16-1.125-1.16ZM7.746 16.5h-2.32V9.012h2.32V16.5Zm-1.16-8.543c-.774 0-1.371-.598-1.371-1.336 0-.738.598-1.371 1.37-1.371.74 0 1.337.633 1.337 1.371s-.598 1.336-1.336 1.336ZM16.5 16.5h-2.355v-3.656c0-.844 0-1.969-1.196-1.969-1.23 0-1.406.95-1.406 1.934V16.5h-2.32V9.012h2.214v1.02h.036c.316-.598 1.09-1.231 2.214-1.231 2.356 0 2.813 1.582 2.813 3.586V16.5Z"
      fill="currentcolor"
      fillRule="nonzero"
    />
  </Icon>
)

export const IconInstagram = (props: IconProps) => (
  <Icon viewBox="0 0 22 22" w="unset" h="unset" {...props}>
    <path
      d="M10.893 6.814a4.044 4.044 0 0 0-4.043 4.043 4.021 4.021 0 0 0 4.043 4.043 4.044 4.044 0 0 0 4.043-4.043c0-2.214-1.829-4.043-4.043-4.043Zm0 6.68a2.633 2.633 0 0 1-2.637-2.637c0-1.441 1.16-2.601 2.637-2.601a2.596 2.596 0 0 1 2.601 2.601c0 1.477-1.16 2.637-2.601 2.637Zm5.132-6.82a.945.945 0 0 0-.949-.95.945.945 0 0 0-.949.95c0 .527.422.949.95.949.526 0 .948-.422.948-.95Zm2.672.949c-.07-1.266-.351-2.39-1.265-3.305-.914-.914-2.04-1.195-3.305-1.265-1.3-.07-5.203-.07-6.504 0-1.266.07-2.355.351-3.305 1.265-.914.914-1.195 2.04-1.265 3.305-.07 1.3-.07 5.203 0 6.504.07 1.266.351 2.355 1.265 3.305.95.914 2.04 1.195 3.305 1.265 1.3.07 5.203.07 6.504 0 1.266-.07 2.39-.351 3.305-1.265.914-.95 1.195-2.04 1.265-3.305.07-1.3.07-5.203 0-6.504Zm-1.687 7.875c-.246.703-.809 1.23-1.477 1.512-1.055.422-3.515.316-4.64.316-1.16 0-3.622.106-4.641-.316a2.665 2.665 0 0 1-1.512-1.512c-.422-1.02-.316-3.48-.316-4.64 0-1.126-.106-3.587.316-4.641A2.712 2.712 0 0 1 6.252 4.74c1.02-.422 3.48-.316 4.64-.316 1.126 0 3.586-.106 4.641.316.668.246 1.195.809 1.477 1.477.422 1.054.316 3.515.316 4.64 0 1.16.106 3.622-.316 4.641Z"
      fill="currentcolor"
      fillRule="nonzero"
    />
  </Icon>
)

export const IconVimeo = (props: IconProps) => (
  <Icon viewBox="0 0 22 22" w="unset" h="unset" {...props}>
    <path
      d="M17.168 3H4.547C3.703 3 3 3.738 3 4.582v12.621a1.56 1.56 0 0 0 1.547 1.547h12.621c.844 0 1.582-.703 1.582-1.547V4.582c0-.844-.738-1.582-1.582-1.582Zm-.914 5.238c-.07 1.125-.844 2.637-2.32 4.57-1.547 2.005-2.883 2.989-3.938 2.989-.668 0-1.23-.598-1.687-1.828-.914-3.27-1.266-5.203-2.004-5.203-.106 0-.387.175-.914.527l-.528-.668c1.301-1.16 2.567-2.426 3.34-2.496.879-.106 1.442.492 1.652 1.793.704 4.605 1.055 5.308 2.356 3.234.492-.773.738-1.336.773-1.722.141-1.16-.914-1.09-1.617-.774.563-1.863 1.652-2.742 3.2-2.672 1.195 0 1.722.774 1.687 2.25Z"
      fill="currentcolor"
      fillRule="nonzero"
    />
  </Icon>
)

export const IconGridSquare = (props: IconProps) => (
  <Icon viewBox="0 0 14 15" fill="none" w="unset" h="unset" {...props}>
    <path fill="currentcolor" d="M0 .889h6v6H0zM8 .889h6v6H8zM0 8.889h6v6H0zM8 8.889h6v6H8z" />
  </Icon>
)

export const IconGridVarying = (props: IconProps) => (
  <Icon viewBox="0 0 15 15" fill="none" w="unset" h="unset" {...props}>
    <path fill="currentcolor" d="M0 .889h9v6H0zM5 8.889h9v6H5z" />
  </Icon>
)

export const IconPaperclip = (props: IconProps) => (
  <Icon viewBox="0 0 12 16" fill="none" w="unset" h="unset" {...props}>
    <path d="M.448 9.616 4.25 3.03a3.5 3.5 0 0 1 6.062 3.5l-4.25 7.361a2 2 0 0 1-3.464-2l3.828-6.63" stroke="#fff" />
  </Icon>
)

export const IconThreeLines = ({ themeType, ...props }: IconThreeLinesType) => {
  const theme = themeType || 'dark'

  const colorLeft = theme === 'white' ? colors.white : colors.blue_1
  const colorMiddle = theme === 'white' || theme === 'dark' ? colors.white : colors.blue_5
  const colorRight = theme === 'white' ? colors.white : colors.red_1

  return (
    <Icon viewBox="0 0 16 12" fill="none" {...props}>
      <g clipPath="url(#iconThreeLinesa)">
        <motion.path
          fill={colorLeft}
          d="m5.578.863.866.5-5.61 9.718-.867-.5z"
          variants={variantsLineOne}
          custom={[colorLeft, colorMiddle, colorRight]}
        />
        <motion.path
          fill={colorMiddle}
          d="m7.507 5.254.866.5-3.94 6.826-.867-.5z"
          variants={variantsLineTwo}
          custom={[colorLeft, colorMiddle, colorRight]}
        />
        <motion.path
          fill={colorRight}
          d="m12.817 3.789.866.5L8.03 14.08l-.866-.5z"
          variants={variantsLineThree}
          custom={[colorLeft, colorMiddle, colorRight]}
        />
      </g>
      <defs>
        <clipPath id="iconThreeLinesa">
          <path fill="#fff" d="M0 0h16v10H0z" />
        </clipPath>
      </defs>
    </Icon>
  )
}

const transition = {
  duration: 0.5,
  ease: 'linear',
}

interface VariantsProps {
  colors: [string, string, string]
}

const variantsLineOne = {
  hover: (colors: VariantsProps) => ({
    fill: [colors[0], colors[2], colors[1], colors[0]],
    transition,
  }),
}

const variantsLineTwo = {
  hover: (colors: VariantsProps) => ({
    fill: [colors[1], colors[0], colors[2], colors[1]],
    transition,
  }),
}

const variantsLineThree = {
  hover: (colors: VariantsProps) => ({
    fill: [colors[2], colors[1], colors[0], colors[2]],
    transition,
  }),
}
